import './Footer.css';

function Footer() {
    return (
        <div className="Footer">
            <footer>
                <p>Website Developed By Jonathan Warner</p>
                <p>Copyright 2020 © Jonathan Warner</p>
            </footer>
        </div>
    );
}

export default Footer;



